<template>
  <div class="">
    <!-- <div class="separator-top  row"></div> -->
    <div class="title-section align-items-center" :class="section">
      <div class="container">
        <div class="px-4 py-5">
          <div class="row">
            <h4 class="pre-title" v-html="pre_title"></h4>
          </div>
          <div class="row">
            <h1 class="title" v-html="title"></h1>
          </div>
          <div class="row" v-if="content">
            <p v-html="content"></p>
          </div>
          <div class="row mt-2">
            <router-link v-if="button_route" class="btn btn-lg btn-primary  col-10 col-sm-6 col-md-4" 
                :to="button_route">{{button_text.toUpperCase()}}</router-link>
            <button v-else class="btn btn-lg btn-primary  col-10 col-sm-6 col-md-4" 
                @click="buttonClicked()">{{button_text.toUpperCase()}}</button>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimplePage",
  props:{
    section:{
        type: String,
        default: ''
    },
    pre_title:{
        type: String,
        default: ''
    },
    title:{
        type: String,
        default: ''
    },
    button_text:{
        type: String,
        default: ''
    },
    button_route:{
        type: String,
        default: ''
    },
    header_image:{
        type: String,
        default: ''
    },
    content:{
        type: String,
        default: ''
    }   
  },
  methods: {
    buttonClicked(){
      this.$emit('buttonClicked');
    }
  }
}
</script>

<style scoped lang="scss">
.title-section {
  margin-top: 50px;
  position: relative;
  border-bottom: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-image: url('~@/assets/img/form-bg.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;  
  height: 0px;
  width: 100%;
  padding-bottom: 60%;

  &.jobs{
    //background-image: url('~@/assets/img/jobs.jpg');
  }
  &.contact{
    //background-image: url('~@/assets/img/contact.jpg');
  }
  .container{
    /*position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);*/
  }
}




@media only screen and (max-width: 1200px) {
  .title-section {
    // height: auto;
    // padding-bottom: 0;
    .container{
      position: relative;
      transform: none;
      left:0;
    }
    .title{
      font-size: 2rem;
    }
    .pre-title{
      font-size: 1.2rem;
    }
  }
}
</style>