<template>
  <div class="">
    <div :class="local_open ? 'show d-block' : ''" class="modal fade " tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="modal-header">
                        <div class="modal-header-logo-container">
                            <img class="modal-logo" src="/img/viendomovies-logo-white.ce9308f8.png" alt="Viendo Movies Logo"/>
                            <h5 class="text-uppercase small text-center font-weight-bold pt-4 d-none d-md-block">{{ $t("El mejor canal de cine en español") }}</h5>
                        </div>
                        <button type="button" class="btn" data-dismiss="modal" aria-label="Close" @click="toggleModal()">
                            <span aria-hidden="true">X</span>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!backdrop_hidden" :class="local_open ? 'show ' : ''" class=" fade modal-backdrop" @click="toggleModal()"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props:{   
    is_open: {
        type: Boolean,
        default: false
    }
  },
  data: function(){
    return {
        local_open: this.is_open,
        backdrop_hidden: !this.is_open
    };
  },
  mounted(){
    this.updateBody();
  },
  methods: {
    toggleModal(val){
        this.local_open = val ? val : !this.local_open;
        if(!this.local_open){
            setTimeout(() => {
                this.backdrop_hidden = !this.local_open;
            }, 100);
        }else{
            this.backdrop_hidden = !this.local_open;
        }

    },
    updateBody(){
        if(this.local_open){
            document.body.classList.add('modal-open');
        }else{
            document.body.classList.remove('modal-open');
        }
    }
  },
  watch: {
    local_open(){
        this.updateBody()
    },
    is_open(val){
        this.toggleModal(val);
    }
  }
}
</script>

<style scoped lang="scss">

    @import "src/assets/styles/colors";
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: $medium-blue;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }

    .modal-backdrop.show {
        opacity: 0.9;
    }

    .modal-header{
        border-bottom: none;
        .modal-header-logo-container{
            text-align: center;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .modal-logo{
                height: auto; 
                width: 60px;
            }
        }
    }

</style>



