<template>
  <div>
    <div v-if="form_success">
        <h5 v-if="form_success_title">{{form_success_title}}</h5>
        <h5 v-else>{{$t('Gracias por suscribirte')}}</h5>
        <p v-if="form_success_content" v-html="form_success_content"></p>
        <router-link class="btn btn-block btn-lg btn-outline-primary btn-outline-light-blue my-4" 
            :to="{name: 'home'}">{{$t('Volver al inicio').toUpperCase()}}</router-link>
    </div>
    <form v-else id="contactForm" ref="form" v-on:submit.prevent="onSubmit" >
        <div class="">
            <div class="mb-5">
                <input name="type" :value="form_type" type="hidden"  />
                <div v-for="inputField in inputs" class="form-group"  :key="inputField.name">
                    <h5 v-if="inputField.title">{{inputField.title.toUpperCase()}}</h5>
                    <p v-if="inputField.subtitle" class="mb-2">{{inputField.subtitle}}</p>
                    <div v-if="['text', 'email', 'numeric'].includes(inputField.type)">
                        <input  class="form-control"
                                :ref="inputField.name"
                                :name="inputField.name"
                                :type="inputField.type"
                                :maxlength="inputField.max_length"
                                :placeholder="inputField.placeholder.toUpperCase()"
                                />
                    </div>
                    <div v-else-if="['textarea'].includes(inputField.type)">
                        <textarea class="form-control"
                                :ref="inputField.name"
                                :name="inputField.name"
                                :type="inputField.type"
                                :placeholder="inputField.placeholder.toUpperCase()"
                                />
                    </div>
                    <div v-else-if="['file'].includes(inputField.type)">
                        <div class="file-container">
                            <input 
                                :ref="inputField.name"
                                @change="fileChanged"
                                type="file" 
                                id="validatedCustomFile" 
                                style="display:none"
                                :name="inputField.name"
                                :accept="inputField.accept"
                                >
                            <label :ref="inputField.name+'_file'" for="validatedCustomFile" class="btn btn-block fake-btn">
                                {{inputField.placeholder.toUpperCase()}}
                            </label>
                            <p v-if="file_name" class="">
                                <i class="mr-2 bi bi-file-text fileIcon"></i>
                                <span class="text-underlined">{{file_name}}</span>
                            </p>
                        </div>

                    </div>
                    <p class="text-error" >
                        {{error_messages[inputField.name]}}
                    </p>
                </div>
            </div>
            <button class="btn btn-primary btn-block mb-5 btn-lg" type="submit">{{submit_text.toUpperCase()}}</button>
        </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "Form",
  props:{
    form_type:{
        type: String,
        default: 'contact',
        validator(value) {
            // The value must match one of these strings
            return ['carreer', 'contact', 'acquisitions', 'cine_club'].includes(value)
        }
    },
    form_success_content: {
        type: String,
        default: ''
    },
    form_success_title: {
      type: String,
      default: ""
    },
    watch_change_prop:{
    },
    submit_text:{
        type: String,
        default: 'Enviar'
    },
    inputs:{
        type: Array,
        default: function(){
            return [
                {
                    type: "text",
                    placeholder: this.$t("Nombre"),
                    name: "name",
                    max_length: 50,
                    required: true
                },
                {
                    type: "email",
                    placeholder: this.$t("Correo electrónico"),
                    name: "email",
                    max_length: null,
                    required: true
                },
                {
                    type: "textarea",
                    placeholder: this.$t("Mensaje"),
                    name: "comment",
                    max_length: 500,
                    required: true
                },
                // {
                //     type: "file",
                //     title: "Adjuntar resume",
                //     subtitle: "Adjuntar un DOC, DOCX, PDF, or TXT file types (1MB max)",
                //     placeholder: "Adjuntar resume",
                //     accept:"image/jpeg,image/png,.doc,.docx,.pdf,.txt",
                //     name: "file",
                //     required: true
                // }
            ];
        }
    }
  },
  data: function(){    
    return {
        error_messages: {},
        file_name: "",
        form_success: false
    };
  },
  computed:{
    submitEnabled(){
        return true;
    }
  },
  watch:{
    watch_change_prop(){
        // the watch_change_prop is the modal visibility.
        this.form_success = false;
    }
  },
  methods:{
    onSubmit(){
        this.error_messages = {};
        if(this.validateInputs()){
            var formData = new FormData(this.$refs["form"]);
            this.$store.dispatch('postForm', formData).then(res => {
                this.formSuccess(res);
            }).catch(err => {
                const response = err.response;
                if(response.status == 400 && response.data && response.data.errors){
                    Object.keys(response.data.errors).map((key)=>{
                        console.log(response.data.errors[key], key);
                        this.error_messages[key] = response.data.errors[key].join(',');
                    });
                }
            });
        }
    },
    clearForm(){
        this.inputs.forEach(input => {
            var inputField = this.$refs[input.name][0]; 
            var errorInput = input.type == 'file' ? this.$refs[input.name+'_file'][0] : inputField;           
            errorInput.classList.remove("error");
            inputField.value = "";
        });
        this.file_name='';
    },
    validateInputs(){
        var valid = true;

        this.inputs.forEach(input => {
            var inputField = this.$refs[input.name][0]; 
            var errorInput = input.type == 'file' ? this.$refs[input.name+'_file'][0] : inputField;           
            errorInput.classList.remove("error");
            if(input.required && !inputField.value){
                valid = false;
                errorInput.classList.add("error");
            }
        });

        return valid;
    },
    formSuccess(data){
        console.log(data);
        this.clearForm();
        this.form_success = true;
        this.$emit('formSuccess');
    },
    fileChanged(event){

        if(event.target.files.length > 0){
            var fileData =  event.target.files[0];
            this.file_name = fileData.name;
        }else{
            this.file_name='';
        }

    }
  }
}
</script>
<style scoped lang="scss">
.fileIcon{
    font-size: 2.5rem;
}

.fake-btn{
    background-color: #000411;
    height: calc(1.8em + 0.75rem + 2px);
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    color:#FFFFFF;
    border: 1px solid #ced4da;
    &:hover{
        background-color: #000000;
    }
}

.fake-btn.error, .form-control.error{
    border: 1px solid rgb(126, 46, 46);
}

.btn{
    padding: 0.5rem 0.75rem;
    //color: #FFFFFF;
    font-weight: 500;
}

.form-control{
    background-color: #000411;
    height: calc(2.5em + 0.75rem + 2px);
    padding: 0.75rem 0.9rem;
    font-weight: 500;
    color:#FFFFFF;
    border-color: white;
    &:focus{
        background-color: #000411;
        color:#EEEEEE;
    }
}
textarea{
    min-height: 90px;
    max-height: 250px;
}
.text-underlined{
    text-decoration: underline;
}
</style>